.investments-heading{
    font-size: 40px;
    color: #295052;
    font-weight: 500;
    line-height: 120%;
}

.hero-investments-bg{
    background: url(../images/hero-1.png);
    background-size: contain;
    width: 85%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: right;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}



@media only screen and (max-width: 900px) {
    .hero-investments-right{
        display: none;
    }
    .investments-heading{
        /* Cheap as chips and easy as pie */



font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 120%;
/* or 34px */
text-align: center;
letter-spacing: -0.04em;

color: #295052;


/* Inside auto layout */
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin-bottom: 100px;

    }

    .hero-investments-bg{
        display: none;
    }
}