.agrostrong-bg{
    background:  url('../images/cattlebg.jpg');
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
 }


.agro-hero-2{
   width: 100%;
   padding-top: 5%;
   padding-bottom: 5%;
   padding-left:20%;
   padding-right:20%;
   text-align: center;
   background-image: url("../images/topribbon.svg");
   background-repeat: no-repeat;
   background-size: contain;
}

.agro-hero-2 .title{
   font-size: 38px;
   font-weight: 500;
   color:#295052;
   margin-top:20px;
}

.agro-cards-container{
   text-align: center;
}

.agro-cards-container .larger-text{
   font-size: 38px;
   margin:0;
   color:#295052;
}

.agro-cards-container .larger-text span{
   position: relative;
}

.agro-benefits-container .larger-text{
   font-size: 38px;
   margin:0;
   color:#ffffff;
   width: 70%;
   text-align: center;
   margin-bottom: 50px;
}

.agro-benefits-container .larger-text span{
   position: relative;
}

 

.agro-cards-flex{
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   margin-top: 1%;
   width: 100%;
}

.agro-card{
   width: 25%;
   min-height: 500px;
   height: 650px;
   margin: 2%;
   border-radius: 18px;
   box-shadow: 5px 11px 30px -16px rgba(125,120,120,1);
   -webkit-box-shadow: 5px 11px 30px -16px rgba(125,120,120,1);
   -moz-box-shadow: 5px 11px 30px -16px rgba(125,120,120,1);
   padding-bottom: 10px;
}

.agro-card-inner{
   width: 350px;
   min-height: 500px;
   height: 650px;
   margin: 2%;
   border-radius: 18px;
   box-shadow: 5px 11px 30px -16px rgba(125,120,120,1);
   -webkit-box-shadow: 5px 11px 30px -16px rgba(125,120,120,1);
   -moz-box-shadow: 5px 11px 30px -16px rgba(125,120,120,1);
   padding-bottom: 10px;
}

 
 /* For tablets and below (less than 768px) */
 @media (max-width: 768px) {
   .agro-card {
     width: 40%; /* Adjusted for medium screens, 2 cards per row */
   }
 }
 
 /* For smaller devices (mobile phones and below, less than 480px) */
 @media (max-width: 480px) {
   .agro-card {
     width: 70%; /* Adjusted for small screens, 1 card per row */
   }
 }

.bg-breeding{
   background-image: url('../images/bg-breeding.jpg');
   height: 250px;
   border-top-left-radius: 18px;
   border-top-right-radius: 18px;
   background-size: cover;
}

.bg-breeding-2{
   background-image: url('../images/breeder-tuli.jpeg');
   height: 250px;
   border-top-left-radius: 18px;
   border-top-right-radius: 18px;
   background-size: cover;
}

.bg-feedlot{
   background-image: url('../images/feedlot.jpeg');
   height: 250px;
   border-top-left-radius: 18px;
   border-top-right-radius: 18px;
   background-size: cover;
}

.agro-card h3{
   font-size: 20px;
   color:#295052;
   margin-top: 10px;
}

.agro-benefits-container{
   width: 100%;
   height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   background:  url('../images/benefits-bg.jpg');
   background-size:cover;
   background-repeat: no-repeat;
   background-position: center;
   margin-top: 50px;
   padding-top: 5%;
   padding-bottom: 5%;
}

.slide-card{
   background-color: #ffffff;
   margin:10;
   border-radius: 20px;
   padding:10px;
   display: flex;
   flex-direction: column;
   text-align: center;
   justify-content: center;
   align-items: center;
}

.slide-card-dark{
   background-color: #ffffff;
   margin:10;
   border-radius: 20px;
   padding:10px;
   display: flex;
   flex-direction: column;
   text-align: center;
   justify-content: center;
   align-items: center;
   /* color:#fff; */

}

.dotgroup{
   height: 5px;
}

.agrostrong-investor{
   height: 100vh;
   text-align: center;
   padding-top: 3%;
}


.agro-bigger {
   height: auto;
 }
 
 .agro-smaller {
   height: 0px;
   visibility: hidden;
   display: none;
 }
 
 @media (max-width: 768px) {
   .agro-cards-flex {
     flex-direction: column; /* Stack child elements vertically on smaller screens */
     align-items: center; /* Keep items centered after changing direction */
   }

   .agro-bigger {
      height: 0px;
      visibility: hidden;
      display: none;
    }
    
    .agro-smaller {
      height: auto;
      visibility: visible;
      display: inline-flex;
    }
}