p{
    font-family: 'Montserrat';
    font-size: 15;
}

.hero-container{
    margin-top: 50px;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
}

.ipo_logo{
    width: 100px;
    height: 100px;
    margin: 0px;
    margin-top: 50px;
    margin-bottom: 50px;

}

.investments-background{
    /* background: linear-gradient(90deg, #F9F9F9 48.96%, #FDFBF6 78.11%, #48878A 78.12%); */

}

p{
    font-size: 15px;
}

.properties-background{
    /* background: linear-gradient(90deg, #F9F9F9 48.96%, #FDFBF6 78.11%, #448094 78.12%); */

}

.insurance-background{
    /* background: linear-gradient(90deg, #FFFFFF 48.96%, #FDFBF6 78.11%, #6A4929 78.12%); */
}


   .modules-title{
    width: 80%;
    text-align: center;
    margin-bottom: 5%;
   }

   .modules-title p {
    text-align: center;
   }

.hero-funeral-column-1 .title{
    color:#448094;
    font-size: 48px;
    margin: 0px;
    font-weight: 500;
}

.tsigiro-background{
   background: linear-gradient(89.58deg, #49878A 0.32%, rgba(73, 135, 138, 0) 76.66%), url('../images/bg4.jpg');
   background-size: cover;
};

.invest-background-container{
    background-color: red;
    min-height: 100vh;
}


.hero-template{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 100%;
}

.hero-content-left{
    display: flex;
    height: 100%;
    width: 60%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-content-right{
    background-image: url("../images/pattern.svg");
    background-size: contain;
    width: 50%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: right;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hero-investments-right{
    width: 50%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: right;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hero-image-properties{
    width: 85%;
    height: 580px;
    border-radius: 12px;
    background-image: url("../images/properties.svg");
    background-size: cover;
}

.hero-image-investments{
    width: 85%;
    height: 580px;
    border-radius: 12px;
    background-image: url("../images/investments.svg");
    background-size: cover;
}

.hero-image-invest{
    width: 85%;
    height: 580px;
    border-radius: 12px;
    background-image: url("../images/invest.jpg");
    background-size: cover;
}

.hero-image-insurance{
    width: 85%;
    height: 580px;
    border-radius: 12px;
    background-image: url("../images/insurance.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

.hero-content-left div{
    width: 90%;
    margin-top: -100px;
}

.hero-content-left div .title{
    font-size: 48px;
    font-weight: 500;
    font-family: 'Montserrat';
    margin: 0px;
    margin-bottom: 20px;
    color:#ffffff;
}

.hero-content-left div p{
    font-size: 20px;
    font-family: 'Montserrat';
    margin: 0px;
    color:#475569
}

.hero-content-left div .title span{
    width: 100%;
    position: relative;
}

span hr{
    position: absolute;
    width: 100%;
    right:0px;
    margin-top: -20px;
    height: 10px;
    background-color:  rgba(230, 161, 96, 0.4);
    border: none;
}

.hero-content-left div button span {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.hero-content-left div button span p{
    color:#ffffff;
    font-size:13px;
    margin:0px
}

.hero-content-left div button{
    width: 130px;
    margin:0px;
    border-radius: 5px;
    align-items: center;
}

.hero-slider-selection{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 330px;
    justify-content: space-between;
    align-self: flex-start;
    margin-left: 50px;
}

.hero-selected-display{
    width: 220px;
    height: 10px;
    margin: 10px;
    background: #BCDADC;
    border-radius: 99px;
    display: flex;
    /* justify-content: center; */
}

.hero-selected-inner{
    height: 100%;
    width: 33.3%;
    background-color: #49878A;
    border-radius: 99px;
}

.hero-selector-hide{
    visibility: hidden;
    width: 33.3%;
}


.hero-text-main .desc{
    padding-inline-end:150px;
}


@media only screen and (max-width: 900px) {

   .hero-content-right{
    display: none;
    height: 0px;;
   }

   .hero-text-main .desc{
    padding-inline-end:0px;
    }


   .hero-template{
    flex-direction: column;
    align-items: flex-start;
    padding-top: 50px;
    background: linear-gradient(89.58deg, #49878A 0.32%, rgba(73, 135, 138, 0) 106.66%), url('../images/bg10.png');
    background-size: cover;
    margin-top: 0px;

   }

  

   .hero-content-left{
    width: 80%;
    /* color:#448094; */
   }



   .modules-title{
    width: 80%;
   }

   .modules-title p {
    text-align: left;
   }

   .investments-background{
    background: linear-gradient(90deg, #F9F9F9 48.96%, #FDFBF6 78.11%, #48878A 78.12%);
    background-image: url("../images/pattern.svg");
    background-repeat: no-repeat;
    background-position: right;
    }
        
    .properties-background{
    /* background: linear-gradient(90deg, #F9F9F9 48.96%, #FDFBF6 78.11%, #48878A 78.12%);
    background-image: url("../images/pattern.svg"); */
    background-repeat: no-repeat;
    background-position: right;
    }

    .insurance-background{
    background: linear-gradient(90deg, #F9F9F9 48.96%, #FDFBF6 78.11%, #48878A 78.12%);
    background-image: url("../images/pattern.svg");
    background-repeat: no-repeat;
    background-position: right;
}

}