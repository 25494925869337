.gallery-cta-container{
    width: 100%;
    min-height: 100vh;
    text-align: center;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding-top: 10%;
    flex-direction: column;
    background-image: url("../images/topribbon.svg");
    background-repeat: no-repeat;
    background-size: contain;
}

.gallery-cta-container .title{
    font-size: 38px;
    font-weight: 500;
    color:#295052;
    margin:0;
}

.gallery-cta-container .title span{
    position: relative

}

.gallery-cta-container p{
    width: 50%;
}

.video-container{
    margin-top: 50px;
    width: 950px;
    height: 750px;
    background-image: url("../images/9.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

.responsiveVideo {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
  }
 
  

@media only screen and (max-width: 900px) {
    .video-container{
        width: 80%;
        height: 80%;
          height: 500px;
        width: 500px;
    }

    .gallery-cta-container{
        padding-top:90px;
        min-height: auto;
      
    }


    .gallery-cta-container p{
        width: 80%;
    }

    iframe {
        width: 100%; 
        padding: 0 5%;
      }
    
}

@media (max-width: 768px) {
    /* Adjust the aspect ratio for smaller screens */
    iframe {
      width: 100%; 
      padding: 0 5%;
    }
  }