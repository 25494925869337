.headingSectionInGallery {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding-left: 15px;
  justify-content: center;
  text-align: left;
  height: 200px;
  margin-top: 5%;
  
}

.mainHeadingInGallery {
  font-size: 16px;
  font-weight: 400;
  color: #48878A;
  margin-bottom: 2%;
  margin-top: 2%;
  margin-left: 64px;
}

.subHeadingInGallery {
  font-size: 3rem;
  color: #295052;
  font-weight: 700;
  margin-left: 64px;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 0 64px;
 
}

.image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;

}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-details {
  background-color: #fefaf5;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  box-sizing: border-box;
  margin: 15px;
}

.event-type {
  margin: 15px;
  padding: 5px;
  color: #D47721;
  border-radius: 5px;
}

.event-date {
  margin: 15px;
  color: #475569;
  white-space: nowrap;
}

.event-description {
  margin: 15px;
  color: #295052;
  font-size: 16px;
}

.explore-button {
  color: #295052;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  margin-left: 15px;
  margin-bottom: 7%;
}

.gallery-container {
  padding: 20px;
}

.cardInGallery {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.icon-arrow::after {
  content: "›";
  margin-left: 5px;
}

.image-container {
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
}

.headingInGallery{
  color: #295052;
  font-size: 24px;
  margin: 15px;
}

.imageInGallery {
  height: 300px;
  object-fit: cover;
}

.imageInBio {
  height: 300px;
  width: fit-content;
}

@media screen and (max-width: 768px) {
  .gallery-container {
    grid-template-columns: repeat(1, 1fr);
    margin: 0 0;
  }
  
  .headingSectionInGallery {
    height: auto;
    margin-top: 30%;
  }
  
  .subHeadingInGallery {
    font-size: 2rem;
    margin-bottom: 5%;
    margin-left: 0;
  }
  
  .event-type {
    font-size: 14px;
  }
  
  .event-date {
    font-size: 14px;
    margin: 5px 5;
  }
  
  .event-description {
    font-size: 14px;
    margin: 5px 5;
  }
  
  .explore-button {
    font-size: 14px;
    margin: 5px 5;
    margin-bottom: 10%;
  }
  
  .event-details {
    margin-left: 15px;
    padding: 10px;
    align-items: center;
    text-align: center;
  }
  
  .cardInGallery {
    border-radius: 5px;
    margin: 10px 0;
  }
  
  .imageInGallery {
    height: 200px;
  }
  
  .headingInGallery {
    font-size: 17px;
    margin: 10px 5;
  }

  .mainHeadingInGallery{
    margin-left: 0;
    margin-bottom: 5%;
  }
}

