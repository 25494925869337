.faq-container{
    width: 100%;
    height: auto;
    background-color: #FDFBF6;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

.faq-left{
    width: 40%;
}

.faq-left .title{
    font-size: 38px;
    margin: 0px;
    font-weight: 600;
    color:#295052;
}

.faq-right{
    width: 40%;
}

.faq-item{
    width: 80%;
    border: 1px solid #E2E8F0;
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    color:#295052;
    margin-top:10px
}

.faq-selector{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color:#295052
}
.faq-item .title{
    font-weight:600 ;
    color:#295052
}

.faq-description p{
    width: 100%;
    margin: 0px;
    margin-bottom: 10px;
    
}

.faq-non-visibility{
    height: 0px;
    visibility: hidden;
}

.faq-visibility{
    height: 100%;
    visibility:visible;
}

@media only screen and (max-width: 900px) {

    .faq-container{
        width: 100%;
        height: auto;
        background-color: #FDFBF6;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .faq-left{
        width: 90%;
        margin-bottom: 30px;
    }

    .faq-right{
        width: 90%;
    }

}