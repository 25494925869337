.location-countries {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: -0.04em;
    color: #475569;
}

.location-address {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.04em;
    color: #475569;
}

.location-view-map {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.04em;
    color: #295052;
}

.location-address-card {
    margin-top: 1.6rem;
}

.container-contact-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .logo-contact-us {
    width: 80px;
    height: 40px;
  }
  
  .title-contact-us {
    font-size: 3rem;
    text-align: center;
    margin: 20px 0;
    color: #295052;
  }
  
  .underline-contact-us {
    border: none;
    border-bottom: 5px dotted #295052;
    width: 30%;
  }

  .ribbon-contact-us {
    background-color: gold;
    height: 20px;
    width: 80%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  table {
    width: 80%;
    border-collapse: collapse;
    margin-top: 20px;
    text-align: center;
  }
  
  th {
    background-color: #295052;
    color: white;
    font-weight: bold;
    font-size: 18px;
    padding: 10px 20px;
  }
  
  th:first-child {
    border-top-left-radius: 10px;
    /* border-bottom-left-radius: 10px; */
    border-right-width: 2px;
    border-left: none;
  }
  
  th:last-child {
    border-top-right-radius: 10px;
    /* border-bottom-right-radius: 10px; */
    border-left-width: 2px;
    border-right: none;
  }
  
  td {
    border: 2px solid #295052;
    padding: 8px 20px;
    font-size: 16px;
  }
  
  td:first-child {
    font-weight: bold;
    border-left: none;
    color: #295052;
  }
  
  td:last-child {
    font-weight: bold;
    border-right: none;
    color: #295052;
  }
  
  td:nth-child(2) {
    border-left-width: 4px;
    border-right-width: 4px;
    color: #295052;
    font-size: 11px;
  }
  
  td:nth-child(3) {
    border-left-width: 0;
    border-right-width: 2px;
  }
  
  th.top {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  th.top:last-child {
    border-top-right-radius: 0px;
  }
  
  th.top:first-child {
    border-top-left-radius: 0px;
  }
  
  th.bottom {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  th.bottom:last-child {
    border-bottom-right-radius: 0px;
  }
  
  th.bottom:first-child {
    border-bottom-left-radius: 0px;
  }

@media only screen and (min-width: 800px) {

    /* CONTACT CHANNELS */
    .contact-channels {
        margin-bottom: 0rem;
        height: 100vh;
    }

    .contact-channels-items {
        text-align: center ;
    }

    /* QUERIES */
    .queries-card { 
        background: #FDFBF6;
        width: 100%;
        height: 336px;
    }

    /* LOCATIONS */
    .location-card {
        margin-left: 2.5rem;
        margin-right: 1.7rem;
        background: #FFFFFF;
    }

    .location-card-holder{
        margin-top: 2rem;
        display: inline-flex;
        width: 100%;
    }
    
    .location-top-content {
        text-align: left;
    }
    .location-items-holder {
        text-align: left;
        width: 100%;
    }
    .map{
        width: 800px;
    }
  }

@media only screen and (max-width: 799px) {
    .location-card {
        margin-left: 0.5rem;
        margin-right: 0.7rem;
        background: #FFFFFF;
    }

    .location-card-holder{
        margin-top: 0rem;
        margin-right: .0.7;
    }
    .contact-channels {
        background: #FFFFFF;
        margin-bottom: 1rem;
        height: 100vh;
    }

    .contact-channels-items {
        align-items: center ;
    }

    .map{
        width: 98%;
    }
  }

  
.contct-modules-cta-container{
    height: 100%;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
    /* background-image: url('../images/ribbon.svg'); */
    /* background-repeat: no-repeat; */
}



.contct-modules-cta-container .larger-text{
    font-size: 38px;
    margin:0;
    color:#295052;
}

.contct-modules-cta-container .larger-text span{
    position: relative;
}
