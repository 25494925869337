.funeral-hero-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100vh;
}

.hero-funeral-bg{
    background: url(../images/newFuneral1.jpg);
    background-size: contain;
    width: 50%;
    height: 65%;
    background-repeat: no-repeat;
    background-position: right;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-right: 10px;
  }

.second-container{
    display: flex;
    background: linear-gradient(90deg, #F9F9F9 48.96%, #FDFBF6 78.11%, #e08d41 78.12%);
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.third-container{
    display: flex;
    height: 100vh;
    justify-content: center;
    /* align-items: center; */
}

.lower-heading{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.04em;
    color: #295052;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 24px;
}

.lower-container{
    display: flex;
    padding-top: 112px;
    background-color: #FFFFFF;
}


.second-paragraph{   
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    /* line-height: 150%; */
    color: #475569;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}


.carousel-funeral-cover {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.img {
    width: 200px;
    height: 200px;
}

.img2 {
    width: 410px;
    height: 300px;
}

.inner-tsigiro-funeral{
    width: 100%;
    height: 100vh;
    background-color: #448094;
    background-image: url("../images/pattern.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.hero-funeral-column-1 {
    display: flex;
    height: 100%;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../images/pattern.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
}

.hero-funeral-column-2{
    width: 80%;
    height: 65%;
    border-radius: 12px;
    background-image: url("../images/Hearse.png");
    background-size: contain;
    background-repeat: no-repeat;
 }

.funeral-trust-fund{
    display: inline;
    width: 35%;
    height: 60%;
    border-radius: 10px;
    background-image: url("../images/video.png");
    background-size: cover;
}

.second-column-1{
    display: flex;
    height: 100%;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

  .custom-list {
    list-style: none;
    padding-left: 0;
}
.custom-list li {
    position: relative;
    padding-left: 20px;
    color: #fff;
}
.custom-list li:before {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    background-image: url('../images/whitetick.svg');
    background-size: cover;
    background-position: center;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.video{
    margin-left: 47px;
    margin-right: 47px;
    margin-top: -80px;
}

.imageVideo{
    width:1277px;
     height:409px;
}



.third-column-1{
    flex-shrink: 0;
    flex-basis: 40%;
    width: 40%;
}

.box {
    /* padding-inline-start: 10px; */
    margin-left: 4rem;
    margin-right: 64px;
}

.hero-funeral-heading {
   font-size: 48px;
    font-weight: 500;
    font-family: 'Montserrat';
    margin: 0px;
    margin-bottom: 20px;
    color:#295052;
}

.hero-third-heading {
   
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 120%;
    letter-spacing: -0.04em;
    color: #295052;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}


@media only screen and (max-width: 900px) {

    .funeral-hero-container {
        flex-direction: column;
        margin-bottom: 0rem
    }

    .hero-funeral-bg{
        display: none;
      }

    .second-container .hero-content-left{
        padding: auto;
    
    }

    .funeral-trust-fund{
        display: none;
    }

    .inner-tsigiro-funeral{
        height: auto;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .hero-funeral-column-1 {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        background-image: url("../images/pattern.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;
     }

    .second-container{
        flex-direction: column;
    }

    .third-container{
        flex-direction: column;
        margin-top: 100px;
        margin-bottom: 0rem
    }

    .hero-funeral-heading {
       font-size: 48px;
        font-weight: 500;
        font-family: 'Montserrat';
        margin: 0px;
        margin-bottom: 20px;
        color:#295052;
    }

    .img1 {
        width: 390px;
        height: 280px;
        background: #49878A;
        flex: none;
        order: 2;
        flex-grow: 0;
    }

    .imageVideo{
        width:400px;
         height:240px;
         margin-left: -60px;
    }

    .third-column-1{
        width: 100%;
    }
}

.paragraph {
    /* Own your first share */

    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    /* identical to box height, or 27px */
    letter-spacing: -0.04em;
    color: #295052;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}