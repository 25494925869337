


.fractional-image-bg{
  background: url(../images/InvestmentsImg.png);
  width: 35%;
  height: 60%;
  border-radius: 10px;
  background-size: cover;
}

.tsigiro-trust-fund{
    display: inline;
    width: 35%;
    height: 60%;
    border-radius: 10px;
    background-image: url("../images/tsigiro.svg");
    background-size: cover;
}

.tsigiro-cattle-units{
    display: inline;
    width: 35%;
    height: 60%;
    border-radius: 10px;
    background-image: url("../images/unittrusts.jpeg");
    background-size: cover;
}

.inner-tsigiro-benefits{
    width: 35%;
    height: 60%;
    border-radius: 10px;
    padding-inline-start: 30px;
}

.inner-tsigiro-benefits .title{
    color:#fff;
    font-size: 38px;
    margin: 0px;
    font-weight: 500;
}

.inner-tsigiro-benefits p{
    color:#fff;
    font-size: 14px;
    margin: 0px;
    margin-top: 10px;
    margin-bottom: 30px;
}

.inner-tsigiro-products{
    width: 100%;
    height: 100vh;
    background-color: #448094;
    background-image: url("../images/pattern.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.benefit-container{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    align-items: center;
}

.benefit-container img{
    height: 20px;
    width: 20px;
}

.benefit-container p {
    margin:0px;
    margin-left: 20px;
}

.or-hover{
    background-color: #E3944B;
    cursor: pointer;
}

.or-hover:hover{
    background-color: #b2743b;
}

@media only screen and (max-width: 900px) {

    .inner-tsigiro-products{
        width: 100%;
        height: auto;
        background-color: #448094;
        background-image: url("../images/pattern.svg");
        background-repeat: no-repeat;
        background-repeat: no-repeat;
        background-position: right;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom:100px;
        padding-top:100px;
    }

    .tsigiro-trust-fund{
        display: none;
    }

    .tsigiro-cattle-units{
    display: none;
    }

    .inner-tsigiro-benefits{
        width: 80%;
    }
}