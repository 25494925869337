.about-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    text-align: center;
    padding-top: 80px;
    padding-bottom: 80px;

}

.about-container-second{
    display: flex;
    padding-top: 112px;
    padding-bottom: 112px;
}

.about-container-third{
    display: flex;
    padding-top: 97px;
    padding-bottom: 97px;
}

.about-container-fourth{
    display: flex;
    padding-top: 97px;
    padding-bottom: 97px;
}

.center-hero-div{
    margin-right: 20%;
    margin-left: 20%;
    color: #475569;
}

.hero-image{
    width: 100%;
}

.headingHero{
    /* About Tsigiro Usekelo */

width: 960px;
height: 67px;
font-style: normal;
font-weight: 700;
font-size: 56px;
line-height: 120%;
text-align: center;
letter-spacing: -0.04em;
color: #295052;
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin-top: 80px;
text-align: center;

}

.box {
    padding: 10px;
    margin-left: 4rem;
    margin-right: 64px;
}


.about-column-1{
    flex-shrink: 0;
    flex-basis: 30%;
    margin-top: 80px;
}

.about-column-2{
    margin-top: 80px;
     color: #475569
}

.about-column-3{
    margin-top: 80px;
}

.partner{
width: 187.56px;
height: 40.61px;
left: 0px;
}

.about-container-partners {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 10%;
    padding-bottom: 10%;
    background-color: #F5F5F5;
  }
  
  .about-partners-left {
    max-width: 800px; /* set the maximum width of the text content */
  }
  
  .about-partners-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .partner-lower {
    margin: 10px;
    max-width: 10%;
    height: auto;
  }
  

.hero-third-heading{
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 140%;
letter-spacing: -0.04em;
color: #295052;
flex: none;
order: 0;
flex-grow: 0;

}

.fourth-heading-upper{

font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: #D47721;
flex: none;
order: 0;
flex-grow: 0;
}

.fourth-heading-lower{
font-style: normal;
    font-weight: 500;
    font-size: 43px;
    line-height: 120%;
    letter-spacing: -0.04em;
    color: #295052;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

}

.paragraph-fourth{
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 150%;
color: #475569;
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;

}


@media only screen and (max-width: 768px) { 

    .about-container {
        flex-direction: column;
        margin-bottom: 0rem
    }

    .about-container-second{
        flex-direction: column;
        margin-top: 100px;
        margin-bottom: 0rem
    }

    .about-container-third{
        flex-direction: column;
        margin-top: 100px;
        margin-bottom: 0rem;
        display: none;
    }

    .about-container-fourth{
        flex-direction: column;
        margin-top: 100px;
        margin-bottom: 0rem;
    }

    .hero-image{
        width: 390px;
    }

.headingHero{
        /* Helping  all Zimbabwean Diaspora to build a home while away from home. */

width: 342px;
height: 156px;

font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 140%;
/* or 39px */
letter-spacing: -0.04em;

color: #295052;


/* Inside auto layout */
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

        
    }

    
    .box {
        margin: 0 0 1rem;
    }

    .about-column-2{
        margin-top: 5px;
    }

    .partner{
        width: 187.56px;
        height: 40.61px;
        left: 0px;
        }
        
       

        .fourth-image{
            display: none;
        }

        .about-column-3{
            margin-top: -100px;
        }

        .about-container-partners {
            flex-direction: column;
          }

          .partner-lower{
            max-width: 100%;
          }
          
          .about-partners-left, .about-partners-right {
            flex-basis: 100%;
            text-align: center;
          }
          
          .about-partners-right {
            margin-top: 20px;
          }
  
}