body{
    font-family: 'Montserrat';
    font-size: smaller;
}

.mobile-menu-selector{
    display: none;
}

.nab-bar{
    width: 100%;
    height:73px;
    position:fixed;
    top:0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f3f3f3;
    background-color: #fff;
    z-index: 100;
}

.nab-bar img{
    width: 248px;
    padding-left: 20px;
}

.nav-links{
    display: flex;
    justify-content: space-around;
    padding-right: 64px;
    width: auto;

}

.nav-links ul{
    display: flex;
    justify-content: space-between;
    width: auto;

}

.nav-links ul ul li{
    width: 100%;
    align-items: center;
}


.nav-links ul > li{
    margin-right:60px;
    list-style: none;
    cursor: pointer;
    width: 80px;
}

.nav-links ul > li:hover{
    cursor: pointer;
    color:#48878A;
}


.nav-buttons{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

 button {
    height: 40px;
    width: auto;
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    margin-right: 10px;
    margin-left:10px;
    background-color:none;
    font-size: smaller;
}

.btn-primary{
    border:1px solid #ccc;
    background-color: transparent;
    cursor: pointer;
    border-radius: 5px;
}

.btn-secondary{
    border:none;
    cursor: pointer;
    border-radius: 5px;
    background-color: #48878A;
    color:#fff;
    margin-left: 0px;
}

.btn:hover{
    background-color: #2e5c5e;
    color:#fff;
    cursor: pointer;
}

/* ltn__drop-menu */
.ltn__drop-menu {
  display: inline-block;
  text-align: left;
  align-items: center;
  display: flex;
}

.ltn__drop-menu li {
  margin-top: 0;
}

.ltn__drop-menu + .ltn__drop-menu {
  margin-left: 10px;
}

.ltn__drop-menu ul {
  margin-bottom: 0;
}

.ltn__drop-menu > ul > li {
  display: inline-block;
  position: relative;
}

.ltn__drop-menu > ul > li > a {
  display: block;
  text-decoration: none;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
}

.ltn__drop-menu ul li ul {
  position: absolute;
  min-width: 150px;
  right: 0;
  background-color: #fff;
  z-index: 999;
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
  border-top: 1px solid #ececec;
  top: 130%;
  padding: 10px 0;
  margin: 0;
  -webkit-transition: opacity 0.2s ease 0.1s, visibility 0.2s ease 0.1s,
    top 0.2s ease 0.1s;
  -o-transition: opacity 0.2s ease 0.1s, visibility 0.2s ease 0.1s,
    top 0.2s ease 0.1s;
  transition: opacity 0.2s ease 0.1s, visibility 0.2s ease 0.1s,
    top 0.2s ease 0.1s;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  visibility: hidden;
  height: 238px;
  width: 450px;
  border:1px solid #eee;
  margin-top: 15px;
  margin-right:-220px;
}

.ltn__drop-menu ul li ul li {
  display: block;
  padding: 5px 15px;
  font-size: 16px;
  color: var(--ltn__paragraph-color);
  font-weight: 500;
}

.ltn__drop-menu ul li ul li a {
  color: inherit;
}

.ltn__drop-menu ul li:hover > ul {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.ltn__drop-menu ul li:hover > a {
  color: var(--ltn__secondary-color);
}

.ltn__drop-menu > ul > li,
.header-wishlist a {
  /* font-size: 20px; */
}

/* header-wishlist */
.header-wishlist {
  color: var(--ltn__heading-color);
}

.hvr-link:hover{
    color:#48878A
}

.hvr-link{
    color:#334155;
    font-weight:600;
    font-size: smaller;
}
.hvr-link span{
    color:#64748B;
    font-weight:400;
}

.hvr-link span:hover{
    color:#48878A
}

.nav-mobile{
    display: none;
}


@media only screen and (max-width: 900px) {
    .nav-links{
        display: none;
    }

    .nav-mobile{
        display: inline;
   }
    
     .mobile-menu-selector{
    display: inline;
}
}

/* The Overlay (background) */
.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */   
  height: 100%;
  width: 0;
  position: fixed; /* Stay in place */
  z-index: 200; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0,0,0); /* Black fallback color */
  background-color: #47878af6; /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */
.overlay-content {
  position: relative;
  top: 25%; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}

/* The navigation links inside the overlay */
.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #ffffff;
  display: block; /* Display block instead of inline */
  transition: 0.3s; /* Transition effects on hover (color) */
}

/* When you mouse over the navigation links, change their color */
.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

/* Position the close button (top right corner) */
.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media screen and (max-height: 450px) {
  .overlay a {font-size: 20px}
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}