

.partners-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }


  
  .partner-input {
    margin-bottom: 20px;
    width: 70%;
  }
  
  .partner-textarea {
    margin-bottom: 20px;
    width: 70%;
    height: 150px;
  }

  .partners-cta-module{
    width: 100%;
    padding: 20px;
    /* border-radius: 10px; */

}
  
  .partner-button {
    width: 70%;
    background-color: #295052;
    color: #fff;
    border: none;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
  }
  
  #partner-form input[type="text"],
  #partner-form input[type="email"],
  #partner-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    background-color: #f5f5f5;
    font-size: 16px;
  }
  
  #partner-form input[type="submit"] {
    background-color: #295052;
    color: #fff;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  #partner-form input[type="submit"]:hover {
    background-color: #1f3846;
  }
  
  #partner-form label {
    font-size: 16px;
    margin-bottom: 10px;
    display: block;
  }
  
  #partner-form select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    background-color: #f5f5f5;
    font-size: 16px;
  }
  
  #partner-form .checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  #partner-form .checkbox-container input[type="checkbox"] {
    margin-right: 10px;
  }
  
  #partner-form .checkbox-container label {
    font-size: 14px;
  }

  

  
  