.bio-section-container{
    padding: 8% 0;
    background-color: #F5F5F5;
}

.bio-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 64px;
    }
  
    .bio-image{
      width: 8000px;
      margin-right: 7%;
    }
  
    .bio-information{
      max-width: fit-content;
    }

    @media screen and (max-width: 768px) {
      .bio-section{
        display: flex;
        flex-direction: column;
        padding: 64px;
        }

        .bio-image{
          width: 100%;
          margin-bottom: 5%;
        }

       
    }    