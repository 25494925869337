.footer{
    background-color: #295052;
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    color:#fff;
    position: relative;
}

.footer-left{
    width: 35%;
    padding: 40px;
}

.footer-left-disclaimer{
    width: 100%;
    padding-left: 40px;
    padding-right: 20px;
    margin-bottom: 100px;
}

.footer-left img{
    height: 70px;
}

.footer-right{
    width: 70%;
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}


input[type="text"]{
    width: 250px;
    height: 35px;
    padding-inline-start: 5px;
    border: none;
    font-family: 'Montserrat';
    color:#575757;
    border-radius: 5px;
    margin-right: 10px;
}

input[type="text"]:focus{
   outline: none;
}

.footer-links{
    max-width: 300px;
}

.footer-links-legal {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    
  }
  
  .legal-info {
    display: flex;
    justify-content: space-between;
  }
  
  .legal-info-column {
    flex-basis: calc(50% - 10px);
  }
  
  .legal-info-column + .legal-info-column {
    margin-left: 20px;
  }
  


.footer-right .footer-links .title{
    font-weight: 600;
    margin-bottom: 30px;
}

.footer-container{
    display: flex;
    flex-direction: column;
    background-color: #295052;
    min-height: 50vh;
    background-image: url("../images/footerbottom.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    color:#fff;
}

.footer-bottom{
    width: 95%;
    height: 10px;
    align-self: center;
    height: 100%;
    border-top: 1px solid #cccccc66;
    margin-top: 20px;
    font-size: smaller;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.privacy-links{
    display: flex;
    justify-content: space-between;
}

.privacy-links p{
    margin:10px;
    cursor: pointer;
    text-decoration: underline;
}

@media only screen and (max-width: 900px) {
    
.footer{
    background-color: #295052;
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    color:#fff;
    position: relative;
}

.footer-left{
    width: auto;
}

.footer-right{
    width: auto;
    background-color: #295052;
    padding: 0px;
    padding-inline: 50px;
}
.subscription-container{
    width: auto;
    display: flex;
}

.footer-right{
    display: flex;
    /* flex-direction: column; */
}

.faviconFooter{
    display: none;
}
   
    
}
