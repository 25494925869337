.portfoliocta{
    height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    background-image: url("../images/topribbon.svg");
    background-repeat: no-repeat;
    background-size: contain;

}

.portfoliocta .title{
    font-size: 38px;
    margin: 0px;
    font-weight: 600;
    color: #295052;
    position: relative;
}

.portfoliocta .title span{
    position: relative;
}

.portfolia-image-a{
    position: absolute;
    width: 20%;
    height: 50%;
    background-color: #295052;
    bottom:0px;
    background-image: url("../images/s4.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    left:0px;
    border-top-right-radius: 10px;
}

.portfolia-image-b{
    position: absolute;
    width: 23%;
    height: 30%;
    background-color: #3d7a7d;
    background-image: url("../images/S3.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    bottom:0px;
    left:25%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.portfolia-image-c{
    position: absolute;
    width: 23%;
    height: 30%;
    background-color: #3d7a7d;
    background-image: url("../images/11.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    bottom:0px;
    right:25%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.portfolia-image-d{
    position: absolute;
    width: 20%;
    height: 50%;
    background-color: #295052;
    bottom:0px;
    right:0px;
    border-top-left-radius: 10px;
    background-image: url("../images/Properties.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@media only screen and (max-width: 900px) {

    .portfolia-image-a{
        display: none;
    }

    .portfolia-image-b{
        display: none;
    }

    .portfolia-image-c{
        display: none;
    }

    .portfolia-image-d{
        display: none;
    }
    
}