.gallery-container-explore {
    margin-top: 2em;
    padding: 1em;
    margin-left: 64px;
    margin-right: 64px;
    margin-top: 10%;
  }
  
  .workshop-label-explore {
    background-color: #FEFAF5;
    color: #D47721;
    padding: 0.5em 1em;
  }

  .link-explore{
    color: #295052;
    text-decoration: none;
  }

  .gallery-heading-explore{
    color: #334155;
    font-size: 16px;
    margin-bottom: 2rem;
  }
  
  .heading-section-explore {
    color: #295052;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 40px;
  }
  
  .gallery-image-explore {
    width: 100%;
  }
  
  .gallery-description-explore {
    color: #475569;
    padding: 1em 0;
  }

  .icon-arrow-explore::after {
    content: "<";
    margin-left: 5px;
  }

  .image-grid-explore {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-left: 64px;
    margin-right: 64px;
    margin-bottom: 5%;
  }
  
  .image-grid-explore img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .padding-span-explore{
    margin-left: 20px;
  }
  
  @media screen and (max-width: 768px) {
    .gallery-container-explore {
      margin-left: 5px;
      margin-right: 5px;
      margin-top: 25%;
    }
    
    .image-grid-explore {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 5%;
    }
  
    .image-grid-explore img {
      width: 100%;
      height: auto;
      margin: 5px;
      object-fit: cover;
    }
  
    .heading-section-explore{
        font-size: 18px;
    }
  }
  
  