.tsigiro-lower {
    width: 40%;
    height: 60%;
    border-radius: 10px;
    background-image: url("../images/Rectangle\ 5.png");
}


@media screen and (max-width: 600px) {
    .hero-tsigiro {
        flex-direction: column;
        margin-top: auto;
        margin-bottom: auto;
    }
}

.hero-container {
    display: flex;
}

.hero-column-1 {
    flex-shrink: 0;
    width: 30%;
}

.box {
    padding: 10px;
    margin-left: 4rem;
    margin-right: 64px;
}

.hero-heading {
    width: 643px;
    height: 58px;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.04em;
    color: #295052;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

}

.hero-paragraph{
    padding-top: 30px; 
    margin-top: 10px; 
    font-size: 16px; 
}


.hero-lower-img{
    background: url(../images/Rectangle\ 5.png);
    background-repeat: no-repeat;
    width: 50%;
    position: absolute;
    right: 0px;
    height: 60%;
  }

@media only screen and (max-width: 900px) {
    
    .hero-container {
        flex-direction: column;
        /* margin-top: 100px; */
        margin-bottom: 0rem
    }

    .hero-lower-img{
        background: url(../images/Rectangle\ 5.png);
        background-repeat: no-repeat;
        width: 100%;
        position: absolute;
        height: 60%;
      }

    .box {
        margin: 0 0 1rem;
    }

    .hero-heading {
        /* Investing made easy. */

        width: 342px;
        height: 34px;
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 120%;
        letter-spacing: -0.04em;
        color: #295052;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;

    }

    .img1 {
        /* image:mobile */

        width: 390px;
        height: 280px;

        background: #49878A;

        /* Inside auto layout */
        flex: none;
        order: 2;
        flex-grow: 0;

    }

    .hero-paragraph{
        padding-top: 60px; 
        margin-top: 80px; 
        font-size: 16px; 
    }

    .hero-column-1{
        width: 100%;
    }
}

.paragraph {
    /* Own your first share */

    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    /* identical to box height, or 27px */
    letter-spacing: -0.04em;
    color: #295052;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}