.modules-cta-section{
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    /* align-items: center; */
}

.single-cta-module{
    width: 100%;
    padding: 20px;
    border-radius: 10px;

}

.single-cta-module:hover{
    width: 100%;
    margin:0;
    height: 100%;
    cursor: pointer;
        box-shadow: 1px 6px 44px -30px rgba(0,0,0,0.93);
-webkit-box-shadow: 1px 6px 44px -30px rgba(0,0,0,0.93);
-moz-box-shadow: 1px 6px 44px -30px rgba(0,0,0,0.93);
}

.ribbon-cta-container{
    width: 22%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    border:1px solid #eee;
    /* background-color: red; */
    
}

.single-cta-module h3{
    font-size: 20px;
    color:#295052;
}

.hovered-link{
    color:#295052;
}

.hovered-link:hover{
    cursor: pointer;
    color:#1c3839;
}

.modules-cta-container{
    height: 80vh;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-image: url('../images/ribbon.svg'); */
    /* background-repeat: no-repeat; */
}

.modules-cta-container .larger-text{
    font-size: 38px;
    margin:0;
    color:#295052;
}

.modules-cta-container .larger-text span{
    position: relative;
}

@media only screen and (max-width: 900px) {
    
    .modules-cta-section{
    height: auto;
    width: 100%;
    display: inline;
    /* justify-content: space-around; */
    /* align-items: center; */
}

.modules-cta-container{
    height: auto;
    padding-top:50px;
}

.ribbon-cta-container{
    width: 90%;
    border: none;
}
    .modules-title{
        width: auto;
        padding-inline-start: 10px;
        padding-left: 20px;
    }
}

.single-cta-module:hover{
    width: 100%;
    margin:0;
    height: 100%;
    cursor: pointer;
    box-shadow: none;
}


