.faqs-container-first{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    text-align: center;
    padding-top: 80px;

}

.faq-left-lower{
    width: 40%;
    flex-shrink: 0;
    margin-top: 5px;
    margin-left: 10%;
    margin-bottom: 5px;
}

.faqs-upper-heading{
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 130%;
text-align: center;
letter-spacing: -0.04em;
color: #295052;
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

}

.button{
    background-color: #E2E8F0;
    border-radius: 6px;
    height: 40px;
    width: 109px;
    cursor: pointer;
}



.faqs-lower-paragraph{
    /* If your question wasn’t answered or not described in depth, feel free to contact our team for assistance. */
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 150%;
text-align: center;
letter-spacing: -0.04em;
color: #475569;
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;

}

.faq-right-lower{
    width: 40%;
}

.faqs-section-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
}

.faq-item-lower{
    width: 80%;
    border: 1px solid #E2E8F0;
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    color:#295052;
    margin-top:10px
}

.heading-faqs{
/* FAQs */

font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 120%;
text-align: center;
letter-spacing: -0.04em;
color: #295052;
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

}

.faqs-first-paragraph{
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 160%;
text-align: center;
color: #475569;
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
padding-left: 337px;
padding-right: 337px;

}


@media only screen and (max-width: 900px) { 

    .faqs-container-first {
        flex-direction: column;
        margin-bottom: 0rem
    }

    .faqs-first-paragraph{
        padding-left: 0%;
        padding-right: 0%;
    }

    .faqs-section-container{
        display: flex;
        flex-direction: column;
    }

    .faq-left-lower{
        margin-left: 0px;
        margin-top: 0px;
    }
  
}