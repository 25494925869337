.products-info{
    height: 100vh;
    width: 100%;
    position: relative;
    background-color: #F8FBFC;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.products-selector{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-top: 5px;
    padding-bottom: 5px;
    position: absolute;
    width: 331px;
    height: 40px;
    background-color: #EBF4F4;
    border-radius: 5px;
    top:10%;
    right:12%;
}

.products-selector .selected-product {
    background-color: #fff;
    width: 33%;
    text-align: center;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.products-selector  p {
    margin: 0px;
    cursor: pointer;
}

.products-info-image{
    width: 35%;
    height: 60%;
    border-radius: 10px;
}

.products-info-selected{
    width: 40%;
    height: 60%;
    padding-inline-start: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.products-info-child{
    padding-left: 10px;
    cursor: pointer;
}

.products-info-child .title{
    font-size: 30px;
    margin: 0px;
    font-weight: 500;
    color:#2950522a;
}

.products-info-child p{
    margin: 0px;
    margin-top: 10px;
    color:#47556968
}

.selected-inner-child .title{
    font-size: 30px;
    margin: 0px;
    font-weight: 500;
    color:#295052;
}

.selected-inner-child p{
    margin: 0px;
    margin-top: 10px;
    color:#475569
}

.selected-inner-child{
    border-left: 5px solid #E6A160;
    padding-left: 10px;
}

.property-info-background{
    background-image: url("../images/properties.svg");
    background-size: cover;
}

.investments-info-background{
    background-image: url("../images/investments.svg");
    background-size: cover;
}

.insurance-info-background{
    background-image: url("../images/bg8.jpg");
    background-size: cover;
}

@media only screen and (max-width: 900px) {

    .products-info{
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: inherit;
        padding-bottom: 20px;
    }

    .products-selector{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: relative;
    width: 331px;
    height: 30px;
    background-color: #EBF4F4;
    border-radius: 5px;
    align-self: center;
    top: 0px;
    right: 0px;
    margin-bottom: 50px;
   }

   .products-info-image{
    display: none
   }

    .products-info-selected{
        width: 100%;
        height: auto;
        padding-inline-start: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .products-info-child{
        padding: 30px;
        margin-bottom: 5px
    }

  
};
