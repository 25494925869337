.main-terms-of-use{
    display: flex;  
    margin-left: 64px; 
    margin-right: 64px; 
    flex-direction: column; 
    margin-top: 10%;
    margin-bottom: 5%
}


@media screen and (max-width: 768px) {
  .main-terms-of-use{
    margin-top: 20%;
  }
  }