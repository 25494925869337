.main-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: center;
    background-color: #F8FBFC;
    background-repeat: no-repeat;
    max-width: 100%;
    min-height: 100vh;
}

.single-cta-module .title{
    font-size: 30px;
    margin: 0px;
    font-weight: 400;
    color: #295052;
    position: relative;
}

.center-img{
    background: url(../images/hero-1.png);
    background-repeat: no-repeat;
    width: 50%;
    position: absolute;
    right: 50%;
    left: 50%;
    height: 100%;
}


.main-bottom{
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    width: 100%;
    justify-content: space-around;
}

.img-third {
    display: block;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    width: 50%;
    width:48px;
    height:48px;
}

.lowerDiv{
    width: 25%;
    margin-top: 100px;
     margin-right: 64px;
    
}

.upperDiv{
    width: 25%;
     margin-top: 100px;
     margin-right: 64px
}

.heading-third-component{
    color: "#295052";
    font-size: "40px";
    text-align: "center";
}

.middle-image{
  align-self: center;
  align-items: center;
}




@media only screen and (max-width: 900px) {
    .main-bottom {
        /* it place the items in vertical direction */
      flex-direction: column;
      /* margin-top: 100px; */
      margin-bottom: 0rem;
      margin-left: 10px
    }


    .lowerDiv{
        flex-direction: column;
        margin-bottom: 20px;
        margin-top: 25px;
        width: 100%;
    }

    .upperDiv{
        flex-direction: column;
        margin-left: 210px;
        margin-bottom: 20px;
        margin-top: 15px;
    }

    .img-third{
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
    }
   
  }